<template>
  <div>
    <h2 class="mb-4">
      Call Logs
    </h2>
    <div class="col-12 col-sm-6 mb-5">
      <CForm>
        <CFormGroup class="form-group form-row">
          <template #label>
            <slot name="label">
              <label class="col-form-label col-sm-3">
                Date Range
              </label>
            </slot>
          </template>

          <template #input>
            <date-range-picker
              v-model="dateRange"
              opens="right"
              class="col-sm-6 col-lg-5 col-12"
              :locale-data="{ firstDay: 1, format: 'mediumDate' }"
              :min-date="minDate"
              :max-date="maxDate"
              @update="searchCalls"
            >
              <template
                v-slot:input
                style="min-width: 350px;"
              >
                {{ formatDate(selectedDateFrom) }} - {{ formatDate(selectedDateTo) }}
              </template>
            </date-range-picker>
          </template>
        </CFormGroup>

        <CTextarea
          v-model="callerIds"
          label="Caller Ids"
          placeholder="Enter comma separated or new line separated caller Ids"
          horizontal
          rows="3"
        />

        <CTextarea
          v-model="callIds"
          label="Call Ids"
          placeholder="Enter comma separated or new line separated call Ids"
          horizontal
          rows="3"
        />

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <ButtonLoading
              type="button"
              color="success"
              :loading="loading"
              @click="searchCalls"
            >
              <CIcon
                name="cil-search"
                class="mr-2"
              />Search
            </ButtonLoading>

            <div
              v-if="invalidFilters"
              class="invalid-feedback d-flex mt-2"
            >
              <CIcon
                name="cilWarning"
                class="mr-1"
              />
              Please enter at least 1 caller Id or call Id.
            </div>
          </CCol>
        </div>
      </CForm>
    </div>

    <div>
      <CDataTable
        small
        border
        sorter
        :loading="loading"
        column-filter
        :items="callsFormatted"
        :fields="fields"
        :items-per-page="15"
        :sorter-value="{ column: 'callDate', asc: false }"
        :pagination="{ align: 'center' }"
        :no-items-view="{ noResults: 'No calls found', noItems: 'No calls, search with different filters' }"
      >
        <template #durationInSeconds="{item}">
          <td class="text-right">
            {{ item.duration }}
          </td>
        </template>

        <template #connectedDurationInSeconds="{item}">
          <td class="text-right">
            {{ item.connectedDuration }}
          </td>
        </template>

        <template #callDate="{item}">
          <td>
            {{ item.callDateFormatted }}
          </td>
        </template>

        <template #callId="{item}">
          <td class="text-right">
            <div class="d-flex align-items-center">
              <div
                class="text-truncate w-fixed-70 mr-2"
              >
                {{ item.callId }}
              </div>

              <a
                href
                @click.prevent="copyCallIdToClipboard(item.callId)"
              ><CIcon
                name="cil-copy"
              /></a>
            </div>
          </td>
        </template>

        <template #callerId="{item}">
          <td class="text-right">
            <div class="d-flex align-items-center">
              {{ item.callerId }}

              <a
                href
                class="ml-1"
                @click.prevent="copyCallerIdToClipboard(item.callerId)"
              ><CIcon
                name="cil-copy"
              /></a>
            </div>
          </td>
        </template>

        <template #conversionAmount="{item}">
          <td class="text-right">
            {{ formatAmount(item.conversionAmount) }}
          </td>
        </template>

        <template #payoutAmount="{item}">
          <td class="text-right">
            {{ formatAmount(item.payoutAmount) }}
          </td>
        </template>

        <template #sellerAccountName="{ item }">
          <td>
            <router-link
              v-if="item.sellerAccountId"
              :to="{ name: 'SellerAccountDetails', params: { id: item.sellerAccountId} }"
              target="_blank"
            >
              <strong>{{ item.sellerAccountName }}</strong>

              <CIcon
                name="cil-external-link"
                size="custom"
                class="ml-1"
              />
            </router-link>
          </td>
        </template>

        <template #buyerAccountName="{ item }">
          <td>
            <router-link
              v-if="item.buyerAccountId"
              :to="{ name: 'BuyerAccountDetails', params: { id: item.buyerAccountId} }"
              target="_blank"
            >
              <strong>{{ item.buyerAccountName }}</strong>

              <CIcon
                name="cil-external-link"
                size="custom"
                class="ml-1"
              />
            </router-link>
          </td>
        </template>

        <template #buyerCampaignName="{ item }">
          <td>
            <router-link
              v-if="item.buyerCampaignId"
              :to="{ name: 'BuyerCampaignDetails', params: { id: item.buyerCampaignId } }"
              target="_blank"
            >
              <strong>{{ item.buyerCampaignName }}</strong>

              <CIcon
                name="cil-external-link"
                size="custom"
                class="ml-1"
              />
            </router-link>
          </td>
        </template>

        <template #sellerOfferName="{ item }">
          <td>
            <router-link
              v-if="item.sellerOfferId"
              :to="{ name: 'SellerOfferEdit', params: { id: item.sellerOfferId } }"
              target="_blank"
            >
              <strong>{{ item.sellerOfferName }}</strong>

              <CIcon
                name="cil-external-link"
                size="custom"
                class="ml-1"
              />
            </router-link>
          </td>
        </template>

        <template #creativeId="{item}">
          <td class="text-right">
            {{ getSafe(() => item.creativeId) }}
          </td>
        </template>

        <template #cmsId="{item}">
          <td class="text-right">
            {{ getSafe(() => item.cmsId) }}
          </td>
        </template>

        <template #Actions="{item}">
          <td>
            <CButton
              v-if="item.recordingUrl"
              color="info"
              size="sm"
              class="mt-1 mr-2"
              :href="item.recordingUrl"
              target="_blank"
            >
              Recording
            </CButton>

            <CButton
              color="info"
              size="sm"
              class="mt-1"
              @click="setChannelToBlockFromCall(item)"
            >
              Block Channel
            </CButton>
          </td>
        </template>
      </CDataTable>
    </div>

    <CModal      
      v-if="callToBlockChannel"
      title="Confirm channel block"
      color="warning"
      :show.sync="showBlockChannelModal"
    >
      <p>
        Are you sure you want to block <strong><u>{{ callToBlockChannel.channel }}</u></strong> channel with seller as <strong><u>{{ callToBlockChannel.sellerAccountName }}</u></strong> for buyer <strong><u>{{ callToBlockChannel.buyerAccountName }}</u></strong>?
      </p>

      <template #footer>
        <CButton
          color="light"
          @click="showBlockChannelModal = false"
        >
          Cancel
        </CButton>

        <ButtonLoading
          color="danger"
          :loading="isLoadingBlockChannel"
          @click="confirmBlockChannel"
        >
          Block
        </ButtonLoading>        
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'

import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { 
  formatDateTime,
  formatDate,
  convertDurationInSecsToTimeFormat, 
  formatAmount 
} from '@/utils'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import formGenericMixin from '@/mixins/form-generic-mixin'

const MAX_DATE_RANGE = 120

export default {
  name: 'CallLogs',

  title: 'Call Logs',

  mixins: [copyToClipboardMixin, formGenericMixin],

  data() {
    return {
      minDate: moment().startOf('year').toDate(),
      maxDate: moment().endOf('day').toDate(),
      showBlockChannelModal: false,
      salesChannelName: '',
      callToBlockChannel: undefined,
      isLoadingBlockChannel: false,
      fields: [
        { key: 'callDate', label: 'Call Date (in local time)', _classes: 'w-fixed-200'  },
        { key: 'categoryName', label: 'Category', },
        { key: 'callId', label: 'Call Id', sorter: false  },
        { key: 'callerId', label: 'Caller Id', sorter: false  },
        { key: 'sellerAccountName', label: 'Seller', },
        { key: 'buyerAccountName', label: 'Buyer', },
        { key: 'buyerCampaignName', label: 'Buyer Campaign', },
        { key: 'channel', label: 'Channel', },
        { key: 'trafficSource', label: 'Traffic Source', },
        { key: 'language', _classes: 'w-fixed-70' },
        { key: 'country', _classes: 'w-fixed-70' },
        { key: 'conversionAmount', label: 'Conversion Amount', _classes: 'text-right' },
        { key: 'payoutAmount', label: 'Payout Amount', _classes: 'text-right' },
        { key: 'durationInSeconds', label: 'Duration', _classes: 'text-right' },
        { key: 'connectedDurationInSeconds', label: 'Connected Duration', _classes: 'text-right' },
        { key: 'sellerOfferName', label: 'Seller Offer',  },
        { key: 'creativeId', label: 'Creative Id',  },
        { key: 'cmsId', label: 'SMID (CMS Id)',  },
        { key: 'Actions', filter: false, sorter: false },
      ]
    }
  },

  computed: {
    ...mapState('callLogs', [
      'searchForCallIds',
      'searchForCallerIds',
      'dateFrom',
      'dateTo',
      'calls'
    ]),

    ...mapState('callLogs', {
      loading: state => state.isLoading
    }),

    invalidFilters() {
      return this.submitted && !this.callIds?.trim() && !this.callerIds?.trim()
    },

    callsFormatted() {
      return this.calls.map(call => {
        return {
          ...call,
          sellerAccountName: call.sellerAccountName ?? '',
          buyerAccountName: call.buyerAccountName ?? '',
          channel: call.channel ?? '',
          country: call.country ?? '',
          sellerOfferName: call.sellerOfferName ?? '',
          callDateFormatted: formatDateTime(call.callDateTimeOffset),
          duration: convertDurationInSecsToTimeFormat(call.durationInSeconds),
          connectedDuration: convertDurationInSecsToTimeFormat(call.connectedDurationInSeconds),
          trafficSource: call.trafficSourceName ? `${call.trafficSourceType}-${call.trafficSourceName}` : ''
        }
      })
    },

    dateRange: {
      get() {
        return {
          startDate: this.selectedDateFrom,
          endDate: this.selectedDateTo
        }
      },
      set({ startDate, endDate }) {
        if (endDate - startDate > this.daysToMilliseconds(MAX_DATE_RANGE)){
          showErrorMessage(`Time range cannot be longer than ${MAX_DATE_RANGE} days`)
          return
        }

        this.selectedDateFrom = moment(startDate).startOf('day').toDate()
        this.selectedDateTo = moment(endDate).endOf('day').toDate()
      }
    },

    selectedDateFrom: {
      get() {
        return this.dateFrom
      },
      set(value) {
        this.SET_DATE_FROM(value)
      }
    },

    selectedDateTo: {
      get() {
        return this.dateTo
      },
      set(value) {
        this.SET_DATE_TO(value)
      }
    },

    callerIds: {
      get() {
        return this.searchForCallerIds
      },
      set(value) {
        this.SET_CALLER_IDS(value)
      }
    },

    callIds: {
      get() {
        return this.searchForCallIds
      },
      set(value) {
        this.SET_CALL_IDS(value)
      }
    }
  },

  methods: {
    ...mapActions('callLogs', [
      'searchForCalls'
    ]),

    ...mapMutations('callLogs', [
      'SET_DATE_FROM',
      'SET_DATE_TO',
      'SET_CALL_IDS',
      'SET_CALLER_IDS',
    ]),

    ...mapActions('salesChannel', ['block']),

    setChannelToBlockFromCall(call) {
      this.callToBlockChannel = call
      this.showBlockChannelModal = true
    },

    async searchCalls() {
      this.submitted = true

      if (this.callIds?.trim() || this.callerIds?.trim()) {
        await this.searchForCalls()
      }
    },

    async confirmBlockChannel() {
      try {
        this.isLoadingBlockChannel = true

        let request = {
          language: this.callToBlockChannel.language,
          country: this.callToBlockChannel.country,
          trafficSourceId: this.callToBlockChannel.trafficSourceId,
          categoryId: this.callToBlockChannel.categoryId,
          buyerAccountId: this.callToBlockChannel.buyerAccountId,
          sellerAccountId: this.callToBlockChannel.sellerAccountId
        }

        await this.block({ payload: request })

        showSuccessMessage('Successfully blocked channel')
      } catch {
        //
      } finally {
        this.isLoadingBlockChannel = false
        this.showBlockChannelModal = false
      }
    },

    formatDateTime,

    formatDate,

    formatAmount,

    copyCallIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Call Id copied to clipboard')
    },

    copyCallerIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Caller Id copied to clipboard')
    },

    daysToMilliseconds(days) {
      return days * 24 * 60 * 60 * 1000
    }
  },
}
</script>
<style lang="scss" scoped></style>